<template>
  <div
    :class="{
      'absolute left-0 top-0 size-full bg-white': isChild,
      'overflow-hidden': selectedLink && selectedLink.children.length,
    }"
  >
    <div v-if="isChild">
      <div class="pl-4">
        <FimButton type="link" class="border-none" @click="$emit('back')">
          <template #icon>
            <LazyIconFielmannArrowLeft class="size-4" />
          </template>
          {{ $t('lookbooks.back').toLowerCase() }}
        </FimButton>
      </div>
      <div @click="$emit('click:item', parent)">
        <FimLink
          v-if="parent && parent.href && parent.label"
          :to="localePath(parent.href)"
          text-size="md"
          only-exact-active
          active-class="font-medium"
          class="align-center flex h-12 w-full justify-between border-y border-gray-300 px-5 pl-9"
        >
          {{ $t('navigation.overview') + ' ' + parent.label }}
          <LazyIconFielmannArrowRight class="h4 inline-block w-4" />
        </FimLink>
      </div>
    </div>
    <ul>
      <li v-for="(link, index) in navigationTree" :key="index">
        <!-- main page will not parse any children, only show root links -->
        <div v-if="!isChild" class="border-b border-gray-300 p-2">
          <FimButton
            v-if="link.children.length > 0"
            type="tertiary"
            class="align-center flex size-full justify-between border-none"
            @click="openChildren(link)"
          >
            {{ link.label }}
            <LazyIconFielmannArrowRight class="h4 inline-block w-4" />
          </FimButton>
          <FimButton
            v-else
            type="tertiary"
            is-link
            :to="localePath(link.href)"
            text-size="md"
            active-class="font-medium"
            class="align-center flex size-full justify-between"
            @click="$emit('click:item', link)"
          >
            {{ link.label }}
            <LazyIconFielmannArrowRight class="h4 inline-block w-4" />
          </FimButton>
        </div>
        <div v-else>
          <!-- will parse a view where first level of children will be visible -->
          <SideNavigationChildrenListElement
            :link="link"
            @open-children="openChildren"
            @click:item="$emit('click:item')"
          />
        </div>
      </li>
    </ul>
    <SlideInFromRightTransition>
      <SideNavigationList
        v-if="selectedLink && selectedLink.children.length"
        :navigation-tree="selectedLink.children"
        :parent="selectedLink"
        :level="level + 1"
        @back="goBack"
        @click:item="
          () => {
            selectedLink = null
            $emit('click:item')
          }
        "
      />
    </SlideInFromRightTransition>
  </div>
</template>

<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'
import type { NavigationLink } from '~/rpcMethods/navigation'

defineEmits(['click:item', 'back'])

const props = defineProps({
  navigationTree: {
    type: Array as PropType<NavigationLink[]>,
    default: () => [],
  },
  parent: {
    type: Object as PropType<NavigationLink>,
    default: null,
  },
  level: {
    type: Number as PropType<number>,
    default: 0,
  },
})

const localePath = useFimLocalePath()

const {
  selectSideNavigationEntry,
  resetSideNavigationEntry,
  selectedSideNavigationEntries,
} = useUiState()

// default to false to prevent overlapping animations when opening with pre-values
const useChildTransitions = ref(false)
const selectedLink = ref<NavigationLink | null>()

const openChildren = (link: NavigationLink) => {
  selectedLink.value = link
  selectSideNavigationEntry(link, props.level)
}

const goBack = () => {
  selectedLink.value = null
  resetSideNavigationEntry(props.level)
}

onMounted(() => {
  // Restore last opened link
  if (selectedSideNavigationEntries.value[props.level]) {
    selectedLink.value = selectedSideNavigationEntries.value[props.level]
  }

  nextTick(() => (useChildTransitions.value = true))
})

const isChild = computed(() => props.level > 0)
</script>
